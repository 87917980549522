import React from 'react';
import HorizontalScroll from 'react-scroll-horizontal';

const list = [
  {
    id: 'a',
    firstname: 'Robin',
    lastname: 'Wieruch',
    year: 1988,
  },
  {
    id: 'b',
    firstname: 'Dave',
    lastname: 'Davidds',
    year: 1990,
  },
  {
    id: 'c',
    firstname: 'Robin',
    lastname: 'Wieruch',
    year: 1988,
  },
  {
    id: 'd',
    firstname: 'Dave',
    lastname: 'Davidds',
    year: 1990,
  },
  {
    id: 'e',
    firstname: 'Robin',
    lastname: 'Wieruch',
    year: 1988,
  },
  {
    id: 'f',
    firstname: 'Dave',
    lastname: 'Davidds',
    year: 1990,
  },
  {
    id: 'g',
    firstname: 'Robin',
    lastname: 'Wieruch',
    year: 1988,
  },
  {
    id: 'h',
    firstname: 'Dave',
    lastname: 'Davidds',
    year: 1990,
  }
];

export default function Recommandation() {
  return (
    <section className="recoBackground">
      <h1 className="homeTitle">Recommandations</h1>
      <div className="hero-body">
          <HorizontalScroll
          pageLock={ true }
          //reverseScroll={true }
          style={{overflow: "visible", height: "370px"}}
          //config={{ stiffness: 0, damping: 0 }}
          >
            {list.map(item => {
            const ref = React.createRef();

            return (
              <li
                key={item.id}
                ref={ref}
                className="recoChild"
                //style={{ height: '250px', width:"250px", border: '1px solid black', listStyle: "none"}}
              >
                  {item.firstname} {item.lastname}, {item.year}
              </li>
            );
          })}
        </HorizontalScroll>
 
     </div>
    </section>
  )
}
