import React from 'react'

export default function HomeContent() {
  return (
    <section id="categories" style={{padding:"20px 10px"}}>
        <div className="columns features">
            <div className="column">
                <div className="card is-shady">
                    <div className="card-content">
                        <div className="content">
                            <h4>Catégorie 1</h4>
                            <p>Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec. Integer enim neque volutpat ac tincidunt vitae semper quis. Accumsan tortor posuere ac ut consequat semper viverra nam.</p>
                            <p><a href="/">En voir plus..</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="card is-shady">
                    <div className="card-content">
                        <div className="content">
                            <h4>Catégorie 2</h4>
                            <p>Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec. Integer enim neque volutpat ac tincidunt vitae semper quis. Accumsan tortor posuere ac ut consequat semper viverra nam.</p>
                            <p><a href="/">En voir plus..</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="card is-shady">
                     <div className="card-content">
                        <div className="content">
                            <h4>Catégorie 3</h4>
                            <p>Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec. Integer enim neque volutpat ac tincidunt vitae semper quis. Accumsan tortor posuere ac ut consequat semper viverra nam.</p>
                            <p><a href="/">En voir plus..</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="card is-shady">
                    <div className="card-content">
                        <div className="content">
                            <h4>Catégorie 4</h4>
                            <p>Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec. Integer enim neque volutpat ac tincidunt vitae semper quis. Accumsan tortor posuere ac ut consequat semper viverra nam.</p>
                            <p><a href="/">En voir plus..</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <h2 className="brandName" id="influenceurs" style={{textAlign: "center", paddingBottom: '0.3em' }}>Nos influenceurs</h2>

        <div className="container-fluid pt-10">
            <div className="row justify-content-md-center ">
               <div className="col-md-10 col-sm-12">
                  <div className="card-columns">
                     <div className="card card-hover h-100" >
                        <div className="card-body">
                           <a href="/">
                              <img className="card-img-top" src="img/photo-1.jpg" alt="Card image cap" />
                              <div className="reveal h-100 p-2 d-flex ">
                                 <div className="w-100 align-self-center">
                                    <p> Curabitur non nulla</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                     <div className="card card-hover h-100" >
                        <div className="card-body">
                           <a href="/">
                              <img className="card-img-top" src="img/photo-2.jpg" alt="Card image cap" />
                              <div className="reveal h-100 p-2 d-flex ">
                                 <div className="w-100 align-self-center">
                                    <p>Vivamus magna convallis</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                     <div className="card card-hover h-100">
                        <div className="card-body">
                           <a href="/">
                              <img className="card-img-top" src="img/photo-3.jpg" alt="Card image cap" />
                              <div className="reveal h-100 p-2 d-flex ">
                                 <div className="w-100 align-self-center">
                                    <p>Lacinia eget consectetur</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                     <div className="card card-hover h-100">
                        <div className="card-body">
                           <a href="/">
                              <img className="card-img-top" src="img/photo-4.jpg" alt="Card image cap" />
                              <div className="reveal h-100 p-2 d-flex ">
                                 <div className="w-100 align-self-center">
                                    <p>Convallis a pellentesque</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                     <div className="card card-hover h-100">
                        <div className="card-body">
                           <a href="/">
                              <img className="card-img-top" src="img/photo-5.jpg" alt="Card image cap" />
                              <div className="reveal h-100 p-2 d-flex ">
                                 <div className="w-100 align-self-center">
                                    <p>Egestas non nisi</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                     <div className="card card-hover h-100">
                        <div className="card-body">
                           <a href="/">
                              <img className="card-img-top" src="img/photo-6.jpg" alt="Card image cap" />
                              <div className="reveal h-100 p-2 d-flex ">
                                 <div className="w-100 align-self-center">
                                    <p>Vivamus magna convallis</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                     <div className="card card-hover h-100">
                        <div className="card-body">
                           <a href="/">
                              <img className="card-img-top" src="img/photo-7.jpg" alt="Card image cap" />
                              <div className="reveal h-100 p-2 d-flex ">
                                 <div className="w-100 align-self-center">
                                    <p>Eget malesuada lacinia</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                     <div className="card card-hover h-100">
                        <div className="card-body">
                           <a href="/">
                              <img className="card-img-top" src="img/photo-8.jpg" alt="Card image cap" />
                              <div className="reveal h-100 p-2 d-flex ">
                                 <div className="w-100 align-self-center">
                                    <p>Donec rutrum congue</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                     <div className="card card-hover h-100">
                        <div className="card-body">
                           <a href="/">
                              <img className="card-img-top" src="img/photo-9.jpg" alt="Card image cap" />
                              <div className="reveal h-100 p-2 d-flex ">
                                 <div className="w-100 align-self-center">
                                    <p>Magna eget rutrum</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <h2 className="brandName" style={{textAlign: "center", paddingBottom: '0.3em' }} id="prix">Nos prix</h2>
         <div className="row">
      <div className="col-md-4">
        <div className="box">
          <div className="head-box">
            <h6>Quick Boostup</h6>
          </div>
          <div className="detail-box">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="box">
          <div className="head-box">
            <h6>Modern Design</h6>
          </div>
          <div className="detail-box">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="box">
          <div className="head-box">
            <div className="img-box">
                    <path d="M294,198H60c-33.084,0-60,26.916-60,60v154c0,33.084,26.916,60,60,60h234c33.084,0,60-26.916,60-60V258C354,224.916,327.084,198,294,198z M314,412c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V258c0-11.028,8.972-20,20-20h234c11.028,0,20,8.972,20,20V412z">
                    <rect y="120" width="40" height="40">
                    <path d="M40,40C17.909,40,0,57.909,0,80h40V40z">
                    <rect x="236" y="40" width="40" height="40">
                    <rect x="158" y="40" width="40" height="40">
                    <rect x="79" y="40" width="40" height="40">
                    <path d="M472,40v40h40C512,57.909,494.091,40,472,40z">
                    <rect x="472" y="354" width="40" height="40">
                    <rect x="472" y="276" width="40" height="40">
                    <rect x="472" y="198" width="40" height="40">
                    <rect x="314" y="40" width="40" height="40">
                    <rect x="472" y="120" width="40" height="40">
                    <rect x="393" y="432" width="40" height="40">
                    <path d="M472,472c22.091,0,40-17.909,40-40h-40V472z">
                    <rect x="393" y="40" width="40" height="40">
                  </rect></path></rect></rect></rect></rect></rect></rect></path></rect></rect></rect></path></rect></path>
              </div>
            <h6>High Resolution</h6>
          </div>
          <div className="detail-box">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
          </div>
        </div>
      </div>
    </div>
    </section>
  )
}
