import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

export default class Navbar extends Component {
  handleLogOut = async event => {
    event.preventDefault();
    try {
      Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
    }catch(error) {
      console.log(error.message);
    }
  }
  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a  href="/">
            <img src="logo-cikaya.png" width="50" height="40" alt="Cikaya logo" />
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
         
            <a href="/" className="navbar-item">
              Home
            </a>

            {!this.props.auth.isAuthenticated && (
            <a href="#categories" className="navbar-item">
              Catégories
            </a>
            )}
            {!this.props.auth.isAuthenticated && (
            <a href="#influenceurs" className="navbar-item">
              Influenceurs
            </a>
            )}
            {!this.props.auth.isAuthenticated && (
            <a href="#prix" className="navbar-item">
              Prix
            </a>
            )}
          {this.props.auth.isAuthenticated && this.props.auth.user && (
            <a href="/products" className="navbar-item">
              Products
            </a>
            )}
            {this.props.auth.isAuthenticated && this.props.auth.user && (
            <a href="/admin" className="navbar-item">
              Admin
            </a>)}
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              {this.props.auth.isAuthenticated && this.props.auth.user && (
                <div className="dropdown">
                  <div className="dropdown-trigger">
                    <button className="button" aria-haspopup="true" aria-controls="dropdown-menu3">
                      <span>Bonjour {this.props.auth.user.username}</span>
                      <span className="icon is-small">
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                  <div className="dropdown-menu" id="dropdown-menu3" role="menu">
                    <div className="dropdown-content">
                      <a href="/" className="dropdown-item">
                        Profil
                      </a>
                      <a href="#" className="dropdown-item">
                        Mes abonnements
                      </a>
                      <hr className="dropdown-divider" />
                      <a href="#" className="dropdown-item">
                        Logout
                      </a>
                    </div>
                 </div>
              </div>
              )}
              <div className="buttons">
                {!this.props.auth.isAuthenticated && (
                  <div>
                    <a href="/register" className="button cta-btn">
                      <strong>Je m'abonne</strong>
                    </a>
                    <a href="/login" className="button is-light">
                      Connexion
                    </a>
                  </div>
                )}
                {this.props.auth.isAuthenticated && (
                  <a href="/" onClick={this.handleLogOut} className="button is-danger">
                    Log out
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}
