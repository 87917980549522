import React, { Fragment, Component } from 'react';
import Recommandation from './Recommandation';
import HomeContent from './HomeContent';

export default class Home extends Component {

  //if (this.props.auth) {
   // console.log(this.props.auth);
  //}
 
  render() {
    console.log("this.props.auth.isAuthenticated::" + this.props.auth.isAuthenticated)
    return (
    <Fragment>
      {this.props.auth.isAuthenticated //&& this.props.auth.user 
      && (
        <div>
          <Recommandation />
          <div className="box cta">
            <p className="has-text-centered">
              <span className="tag is-primary">Nouveauté</span> Dès maintenant vous pouvez retrouver vos vidéos sur notre plateforme
            </p>
          </div>
        </div>
      )}

      {!this.props.auth.isAuthenticated && (
      <div>
        <section className="recoBackground">
          <div style={{paddingTop: "50px", paddingLeft: "50px", paddingBottom: "50px",}}>
            <h2 className="brandName">Cikaya</h2>
            <div style={{paddingLeft: "20px"}}>
              <p style={{fontSize: "28px", fontWeight: "200", color: "lightgray"}}>Votre nouvelle plateforme vidéo</p>
              <a href="/register" className="maxbutton" style={{marginLeft: "30px", marginTop: "30px"}}>
                <span className='mb-text' >Je m'abonne</span>
              </a>
            </div>

            <div>

            </div>
          </div>
        </section>
        <div className="box cta">
          <p className="has-text-centered" id="test">
            <span className="tag is-primary">Nouveauté</span> Dès maintenant vous pouvez retrouver vos vidéos sur notre plateforme
          </p>
        </div>
        <HomeContent />
      </div>
      )}
      
      
    </Fragment>
  )
  }
}